<template>
  <v-container>
    <v-card>
      <v-card-title class="white--text text-h5" style="background-color:#93B372">
        项目介绍
      </v-card-title>
      <v-card-text class="pa-3">
        <h2>简介</h2>
        <p>
          E方言是一款整合方言工具，服务用户依托方言在特定场景下使用、交流、学习的线上平台。通过统一模式，为特定方言（或方言所承载的现象、民俗、艺术）提供定制化方言服务包，快速构建贯通“查（询）、识（别）、（翻）译、（收）集、学（习）”的五大功能模块，营造“引得进、留得住、学得来”的方言环境。同时发挥方言母语者的主观能动，利用收集功能，提升服务包质量，也助力传承根植于铸牢中华民族共同体意识的中华优秀语言文化，助力夯实文化自信之基。
        </p>
        <v-img
            src="https://hinghwadict-1259415432.cos.ap-shanghai.myqcloud.com/website/五大功能模块.png"
            max-width="600px"
            style="margin: 0 auto"
        />
        <h2>市场</h2>
        <p>
          方言是文化的重要载体，仅国家级非物质文化遗产目录中便有超过600项与方言直接相关。而方言文化，更是根植于铸牢中华民族共同体意识的中华优秀语言文化，包含方言现象、方言民俗、方言艺术三大部分。以融入了方言的戏曲为例，无论是秦腔豫剧，还是京剧黄梅，那些生动鲜活、合辙押韵的戏词，唱出了亦俗亦雅的风情，也唱出了中华文化的特色。倘若唱词千篇一律，又何来不同文化的魅力与价值，何来文化生命力的延续？在弘扬中华民族优秀传统文化，坚定文化自信的过程中，不能缺少对方言的保护与传承。
          我们应当充分利用当今飞速发展的互联网和迅猛发展的通信技术为语言资源的保护及开发应用提供必要条件。而现阶段，市面上更多的方言产品仅仅是对方言字词的整理与收集，即便是数字化，也仅仅是提供线上的字词查询，更没有方言服务。
          在方言的应用方面，大多都是个性化、定制化的特殊场景，用户群体小，规模不大。传统的大型企业通过大数据进行分析、加工的方式成本高昂，复杂度高，周期长，难以推广到中小群体。而E方言正是下沉至这些群体，通过统一模式利用少量资源快速搭建起特定方言的服务包，并可以通过母语者的语料再贡献拓展语料包，进一步提升服务包的质量。
        </p>
        <h2>优势</h2>
        <h3>定制化</h3>
        <p>
          针对指定方言（或方言所承载的现象、民俗、艺术）打造指定的服务包。大型企业出于人力、成本等因素的考虑，只会提供大方言的特定功能。而我们的优势在于订制的服务包最基础的服务便已包含“查（询）、识（别）、（翻）译、（收）集、学（习）”的五大功能模块，其他功能也可以个性化订制。
        </p>
        <h3>低资源</h3>
        <p>
          特定方言（或者是方言所承载的现象、民俗、艺术）的语料资源往往并不是特别丰富，在服务包的创建过程时传统方法需要大量的前期收集工作。而定制E方言服务包时的资源下限门槛较低，利用已有的中国语言资源保护工程的相关成果即可搭建出基础服务。
        </p>
        <h3>开放式</h3>
        <p>
          E方言服务包并不是“一次性产品”，而是会在服务包的使用过程中不断积累、收集更多的数据（如方言母语者的主动贡献）。让服务包使用者在线上，在E方言平台内进行“交流”，拓展丰富方言服务包的内容，筑牢中华民族共同体意识。
        </p>
        <h2>预期目标</h2>
        E方言项目庞大，我们将整个项目分为若干部分，逐步完成。
        我们计划在一年内完善技术研发，撰写论文并形成标准化服务流程；在两年内向公众提供较为完善的若干个公共服务包；在三年内正式对外提供服务包的定制服务。科技助力，赋予方言保护传承更多可能。
        <v-img
            src="https://hinghwadict-1259415432.cos.ap-shanghai.myqcloud.com/website/未来设想.jpg"
            max-width="600px"
            style="margin: 0 auto"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Introduction',
  created() {
    this.$store.commit('changeTab', 1)
  }
}
</script>

<style scoped>

</style>
